.topBottomOverflowFadeContainer{
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, transparent 95%, #E0E0E0);
  z-index: -1;
}

.paper {
  background: #fff;
  box-shadow:
    /* The top layer shadow */
    0 1px 1px rgba(0,0,0,0.15),
      /* The second layer */
    0 10px 0 -5px #eee,
      /* The second layer shadow */
    0 10px 1px -4px rgba(0,0,0,0.15),
      /* The third layer */
    0 20px 0 -10px #eee,
      /* The third layer shadow */
    0 20px 1px -9px rgba(0,0,0,0.15);
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140  px; 
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}