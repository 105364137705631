@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
  display: inline;
  height: 20px;
  width: 20px;
}
