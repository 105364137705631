.topBottomOverflowFade {
  width: 100%;
  height: 100px;
  left: 0;
  top: 0;
  overflow-y: hidden;
}

.fade {
  bottom: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(transparent 15%, white);
}
